var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"flex flex-col sm:flex-row lg:flex-row border-b pt-8 pb-12 sm:pb-20"},[_c('h1',{staticClass:"text-2xl tracking-tight flex-1 items-center justify-center"},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('span',{staticClass:"font-body bg-primary"},[_vm._v(" Özgür ")]),_c('span',{staticClass:"font-body-bold"},[_vm._v(" Atmaca ")])])]}}])})],1),_c('nav',{staticClass:"flex sm:items-center text-sm uppercase justify-around\n    md:justify-end tracking-widest flex-col sm:flex-row mt-8 sm:mt-0"},_vm._l((_vm.nav),function(item,i){return _c('ul',{key:i},[_c('li',{staticClass:"px-1 sm:px-5"},[_c('router-link',{attrs:{"to":{ name: item.path, params: { slug: item.path }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"hover:bg-primary",attrs:{"active":isActive,"href":href},on:{"click":navigate}},[(isExactActive)?_c('span',[_vm._v(" ⇾ ")]):_vm._e(),_c('span',{staticClass:"font-body-bold"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}],null,true)})],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }